import  { withStore } from './DBUtils';


export const getColumnsFromDB = () => {
    return new Promise((resolve, reject) => {
        withStore('readonly', store => {
            const transaction = store.db.transaction(['columns'], 'readonly');
            const columnStore = transaction.objectStore('columns');
            const request = columnStore.getAll();

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                console.error('Failed to fetch columns:', event.target.error); 
                reject('Failed to fetch columns');
            };
        });
    });
};

export const saveColumnsToDB = (columns) => {
    return withStore('readwrite', store => {
        return Promise.all(columns.map(column => {
            return new Promise((resolve, reject) => {
                const request = store.put(column);
                request.onsuccess = resolve;
                request.onerror = () => reject('Failed to save column');
            });
        }));
    });
};

export const addColumnToDB = (column) => {
    return withStore('readwrite', store => {
        return new Promise((resolve, reject) => {
            const request = store.add(column);
            request.onsuccess = resolve;
            request.onerror = () => reject('Failed to add column');
        });
    });
};




export const updateColumnInDB = (id, updatedColumn) => {
    return withStore('readwrite', store => {
        const transaction = store.db.transaction(['columns'], 'readwrite');  // 'columns' should be the name of your object store
        const columnStore = transaction.objectStore('columns');
        
        return new Promise((resolve, reject) => {
            // Ensure the updatedColumn object has the correct id
            updatedColumn.id = id; // Set the ID explicitly if not already set
            const request = columnStore.put(updatedColumn);
            request.onsuccess = () => resolve();
            request.onerror = (event) => reject('Failed to update column: ' + event.target.errorCode);
        });
    });
};





export const deleteColumnFromDB = (id) => {
    return withStore('readwrite', store => {
        return new Promise((resolve, reject) => {
            const request = store.delete(id);
            request.onsuccess = resolve;
            request.onerror = () => reject('Failed to delete column');
        });
    });
};

export const addColumnWithChannels = (column) => {
    return withStore('readwrite', (store) => {
        const transaction = store.db.transaction(['columns', 'channels'], 'readwrite');
        const columnStore = transaction.objectStore('columns');
        const channelStore = transaction.objectStore('channels');
        
       

        return new Promise((resolve, reject) => {
            // Attempt to add the column
            const columnRequest = columnStore.add(column);
            columnRequest.onsuccess = () => {
                // Only proceed to handle channels if the column was added successfully
                const channelPromises = column.channels.map(channel => {
                    return new Promise((resolveChannel, rejectChannel) => {
                        // Check if the channel already exists
                        const checkRequest = channelStore.get(channel.id);
                        checkRequest.onsuccess = () => {
                            if (checkRequest.result) {
                                // Channel exists, no need to add
                                resolveChannel();
                            } else {
                                // Channel doesn't exist, add it
                                const addRequest = channelStore.add(channel);
                                addRequest.onsuccess = resolveChannel;
                                addRequest.onerror = () => rejectChannel(`Failed to add new channel: ${channel.id}`);
                            }
                        };
                        checkRequest.onerror = () => rejectChannel(`Failed to check channel existence: ${channel.id}`);
                    });
                });

                // Wait for all channel promises to resolve
                Promise.all(channelPromises)
                    .then(() => resolve())
                    .catch(error => reject(error));  // If any channel operation fails, the whole operation is considered failed
            };
            columnRequest.onerror = () => reject('Failed to add column');
        });
    });
};

export const addChannels = (channels) => {
    return withStore('readwrite', (store) => {
        const transaction = store.db.transaction(['channels'], 'readwrite');
        const channelStore = transaction.objectStore('channels');

        return Promise.all(channels.map(channel => {
            return new Promise((resolveChannel, rejectChannel) => {
                // Check if the channel already exists
                const checkRequest = channelStore.get(channel.id);
                checkRequest.onsuccess = () => {
                    if (checkRequest.result) {
                        // Channel exists, no need to add
                        resolveChannel();
                    } else {
                        // Channel doesn't exist, add it
                        const addRequest = channelStore.add(channel);
                        addRequest.onsuccess = resolveChannel;
                        addRequest.onerror = () => rejectChannel(`Failed to add new channel: ${channel.id}`);
                    }
                };
                checkRequest.onerror = () => rejectChannel(`Failed to check channel existence: ${channel.id}`);
            });
        }));
    });
};

export const getColumnWithChannels = (columnId) => {
    return withStore('readonly', store => {
        return new Promise((resolve, reject) => {
            const columnStore = store.db.transaction('columns', 'readonly').objectStore('columns');
            const channelStore = store.db.transaction('channels', 'readonly').objectStore('channels');

            const columnRequest = columnStore.get(columnId);
            columnRequest.onsuccess = () => {
                const column = columnRequest.result;
                const index = channelStore.index('columnId');
                const channelsRequest = index.getAll(columnId);
                channelsRequest.onsuccess = () => {
                    column.channels = channelsRequest.result;
                    resolve(column);
                };
                channelsRequest.onerror = () => reject('Failed to fetch channels');
            };
            columnRequest.onerror = () => reject('Failed to fetch column');
        });
    });
};


export const clearColumnsAndChannels = async () => {
    await withStore('readwrite', store => {
        const transaction = store.db.transaction(['columns', 'channels'], 'readwrite');
        const columnStore = transaction.objectStore('columns');
        const channelStore = transaction.objectStore('channels');

        return Promise.all([
            new Promise((resolve, reject) => {
                const columnClearRequest = columnStore.clear();
                columnClearRequest.onsuccess = () => resolve();
                columnClearRequest.onerror = () => reject('Failed to clear columns');
            }),
            new Promise((resolve, reject) => {
                const channelClearRequest = channelStore.clear();
                channelClearRequest.onsuccess = () => resolve();
                channelClearRequest.onerror = () => reject('Failed to clear channels');
            })
        ]);
    });
};