import React, { useEffect, useState, useContext } from "react";
import Navbar from "./navbar";
import "./css/DataTables.css";
import { getOrgUsers } from "./utils/UserUtil";
import OrganizationContext from "./getUserContext";
import { Link, useNavigate } from "react-router-dom";

const GetOrgUsersDataTables = () => {
  const navigate = useNavigate();

  const [usersData, setUsersData] = useState([""]);
  const [loading, setLoading] = useState(false);
  const { setOrgId } = useContext(OrganizationContext);

  useEffect(() => {
    fetchData();
  }, []);

  const handleColumnClick = (id) => {
    handleOrgIdChange(id);
    navigate("/admin/GetOrg", { replace: true });
  };

  const handleOrgIdChange = (id) => {
    setOrgId(id);
  };

  const fetchData = async () => {
    try {
      const data = await getOrgUsers(setUsersData);
      setUsersData(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setUsersData([]);
    } finally {
      setLoading(false);
    }
  };
  const buttonStyle = {
    backgroundColor: "#157e18",
    border: "none",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "15px",
    margin: "4px 1px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60%",
    height: "100%",
  };

  return (
    <div className="app-container">
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>Organization List</h1>
        </div>
        <div className="custom-table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th>Organization Name</th>
                <th>Manager Email</th>
                <th>Current Capacity</th>
                <th>Max Capacity</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>View Team</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(usersData) ? (
                usersData.map((item) => (
                  <tr key={item.id}>
                    <td onClick={() => handleColumnClick(item.id)}>
                      {item.name}
                    </td>
                    <td onClick={() => handleColumnClick(item.id)}>
                      {item.manager_email}
                    </td>
                    <td onClick={() => handleColumnClick(item.id)}>
                      {item.current_capacity}
                    </td>
                    <td onClick={() => handleColumnClick(item.id)}>
                      {item.max_capacity}
                    </td>
                    <td>11/18/2024</td>
                    <td>12/18/2024</td>
                    <td>
                      <Link
                        to="/admin/GetOrg"
                        onClick={() => handleColumnClick(item.id)}
                      >
                        <button style={buttonStyle}>View</button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No user data available</td>
                </tr>
              )}
              {Array.isArray(usersData) && usersData.length === 0 && (
                <tr>
                  <td colSpan="7">No users found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GetOrgUsersDataTables;
