import { postAuth } from "../../globalUtils";

const post_url = "/post/media";

export const getPostsFromApi = async ({
  column,
  search,
  page = 1,
  limit = 10,
  maxDate,
}) => {
  try {
    console.log("maxDate sent to API:", maxDate);
    const url =
      `${post_url}?page=${page}&limit=${limit}` +
      (maxDate ? `&maxDate=${maxDate}` : "");
    const formattedData = {
      channels: column.channels.map((channel) => channel.id),
      filters: {
        get_image: column.get_image,
        get_video: column.get_video,
        get_preview: column.get_preview,
        get_text_only: column.get_text_only,
      },
      search: search,
    };

    const response = await postAuth(url, formattedData);

    if (!response.ok) {
      throw new Error("Failed to fetch posts from API");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in getPostsFromApi:", error);
    throw error;
  }
};
