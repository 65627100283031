import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import "./css/DataTables.css";
import { ViewRequests, verifyRequestedUsers } from "./utils/UserUtil";

const ChannelRequestsTables = () => {
  const [usersData, setUsersData] = useState([""]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await ViewRequests();
      setUsersData(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResolve = async (id) => {
    try {
      await verifyRequestedUsers(id);
      fetchData();
    } catch (error) {
      console.error("Failed to unverify user:", error);
    } finally {
      setShowPopup(false);
    }
  };

  const confirmUnverify = (user) => {
    setSelectedUser(user);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedUser(null);
  };

  const buttonStyle = {
    backgroundColor: "#157e18",
    border: "none",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "15px",
    margin: "4px 1px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60%",
    height: "100%",
  };

  return (
    <div>
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>Channels Requests List</h1>
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Channels</th>
              <th>Requested By</th>
              <th>Resolve</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3">Loading...</td>
              </tr>
            ) : usersData.length === 0 ? (
              <tr>
                <td colSpan="3">No users found</td>
              </tr>
            ) : (
              usersData.map((item) => (
                <tr key={item.id}>
                  <td>{item.channel || "N/A"}</td>
                  <td>{item.user_email || "N/A"}</td>
                  <td className="center-content">
                    <button
                      style={buttonStyle}
                      onClick={() => confirmUnverify(item)}
                    >
                      Resolve
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {showPopup && selectedUser && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Confirm Resolve</h2>
            <p>Are you sure you want to Resolve {selectedUser.user_email}?</p>
            <button
              onClick={() => handleResolve(selectedUser.id)}
              style={buttonStyle}
            >
              Yes, Resolve
            </button>
            <button onClick={closePopup} style={buttonStyle}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChannelRequestsTables;
