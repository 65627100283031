import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "./navbar";
import "./css/DataTables.css";
import { getAllChannels, searchForChannel } from "./utils/UserUtil";
import { website } from "../globalUtils";
import ChannelContext from "./getChannelContext";

const GetChannelsDataTables = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { setChannelsId, setChannelsName } = useContext(ChannelContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    // Fetch all channels when the component mounts
  }, []);

  const handleColumnClick = (id, name) => {
    setChannelsName(name);
    setChannelsId(id);
  };

  const fetchData = async (query = "") => {
    setLoading(true);
    try {
      const data =
        query.length > 0
          ? await searchForChannel(query)
          : await getAllChannels();
      // Log the entire data object

      setUsersData(data.channels ? data.channels : data);
    } catch (error) {
      console.error("Failed to fetch channels:", error);
      setUsersData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim() === "") {
      fetchData(); // Explicitly fetch all channels if search term is empty
    } else {
      fetchData(value); // Fetch filtered channels based on search term
    }
  };

  const buttonStyle = {
    backgroundColor: "#157e18",
    border: "none",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "15px",
    margin: "4px 1px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "100%",
    height: "100%",
  };

  return (
    <div>
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>Channels List</h1>
          <input
            type="text"
            placeholder="Search channels..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginBottom: "20px", padding: "10px", width: "100%" }}
          />
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Sender Username</th>
              <th>Chat Title</th>
              <th>Image</th>
              <th>Source URL</th>
              <th>Is Deleted</th>
              <th>View Tags</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(usersData) && usersData.length > 0 ? (
              usersData.map((item) => (
                <tr key={item.id || item.source_url}>
                  <td>{item.sender_username || "N/A"}</td>
                  <td>{item.chat_title || "N/A"}</td>
                  <td>
                    <img
                      src={website + item.image}
                      alt="Channel Image"
                      width={80}
                    />
                  </td>
                  <td>{item.source_url || "N/A"}</td>
                  <td>{item.isDeleted ? "True" : "False"}</td>
                  <td>
                    <Link
                      to="/admin/GetTags"
                      onClick={() =>
                        handleColumnClick(item.id, item.chat_title)
                      }
                    >
                      <button style={buttonStyle}>View Tags</button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No channels found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GetChannelsDataTables;
