import React, { useState } from "react";
import "./css/addUserOrg.css"; // Ensure you create this CSS file
import Navbar from "./navbar";
import { addUserOrg } from "./utils/UserUtil";

const AddUserOrgPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    // Validate name
    if (!name) {
      errors.name = "Name is required.";
      formIsValid = false;
    }

    // Validate email
    if (!email) {
      errors.email = "Email is required.";
      formIsValid = false;
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errors.email = "Invalid email format.";
      formIsValid = false;
    }

    // Validate number
    if (!number) {
      errors.number = "Number is required.";
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        await addUserOrg(setName, setEmail, setNumber, name, email, number);
        alert("Organization added successfully");
      } catch (error) {
        console.error("Failed to add organization:", error);
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className="form-container-1">
      <Navbar />
      <form onSubmit={handleSubmit} className="form-1">
        <div className="input-group-1">
          <label htmlFor="name" className="label-1">
            Name:
          </label>
          <input
            className="input-1"
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <p className="error">{errors.name}</p>}
        </div>
        <div className="input-group-1">
          <label htmlFor="email" className="label-1">
            Email:
          </label>
          <input
            className="input-1"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="input-group-1">
          <label htmlFor="number" className="label-1">
            Number:
          </label>
          <input
            className="input-1"
            type="number"
            id="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          {errors.number && <p className="error">{errors.number}</p>}
        </div>
        <div className="input-group-1">
          <label htmlFor="date" className="label-1">
            Start Date:
          </label>
          <input
            className="input-1"
            type="date"
            id="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          {errors.startDate && <p className="error">{errors.startDate}</p>}
        </div>
        <div className="input-group-1">
          <label htmlFor="date" className="label-1">
            End Date:
          </label>
          <input
            className="input-1"
            type="date"
            id="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          {errors.endDate && <p className="error">{errors.endDate}</p>}
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddUserOrgPage;
