// OrganizationContext.js
import React, { createContext, useState } from "react";

const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const [organizationId, setOrganizationId] = useState("");

  const setOrgId = (id) => {
    setOrganizationId(id);
  };

  return (
    <OrganizationContext.Provider value={{ organizationId, setOrgId }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationContext;
