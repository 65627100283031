import React, { useState } from "react";
import Select from "react-select";
import styled, { useTheme } from "styled-components";

const ThemedInput = styled.input.attrs({
  className: "chooseChannel CHANNELNAMEINPUTNEW",
})`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
`;

const ThemedIconContainer = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
`;

const IconSelect = ({ options, loading, onSelectIcon, ...props }) => {
  const theme = useTheme();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      backgroundColor: theme.popup_background,
      color: theme.text,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 6px",
      color: theme.text, // Ensures text color in value container
    }),
    singleValue: (base) => ({
      ...base,
      color: theme.text, // Ensures selected value text color
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: theme.text,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 40,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: theme.popup_background,
      color: theme.text,
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: theme.popup_background,
      color: theme.text,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected
        ? theme.chip_active
        : isFocused
        ? theme.chip_inactive
        : theme.popup_background,
      color: theme.text,
      ":active": {
        backgroundColor: isSelected ? theme.chip_active : theme.chip_inactive,
      },
    }),
  };

  return (
    <Select
      {...props}
      options={loading ? [] : options} // Show no options while loading
      getOptionLabel={(e) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="material-icons" style={{ marginRight: 10 }}>
            {e.label}
          </span>
          {e.label}
        </div>
      )}
      getOptionValue={(e) => e.value}
      styles={customStyles}
      onInputChange={(inputValue) => props.onSearch(inputValue)}
      noOptionsMessage={() =>
        loading ? (
          <div className="loading-message">
            <div className="spinner"></div> Loading...
          </div>
        ) : (
          "No options"
        )
      }
      onChange={(selectedOption) => onSelectIcon(selectedOption.value)}
    />
  );
};

const FirstColumn = ({
  checkedButtons,
  handleToggle,
  iconOptions,
  onSearch,
  loading,
  onIconChange,
}) => {
  const [selectedIcon, setSelectedIcon] = useState("");

  const handleIconChange = (icon) => {
    setSelectedIcon(icon);
    onIconChange(icon);
  };

  return (
    <div className="RegularColumn QueryView">
      <div className="QueryAlign">
        <div>
          <h3 className="ColumnHeader">Column Name</h3>
          <ThemedInput
            className="ColumnNameInput TextArea"
            placeholder="Column Name"
          />
        </div>
        <div>
          <h3>Icon</h3>
          <ThemedIconContainer className="IconContainer">
            <IconSelect
              className="IconSelect"
              options={iconOptions}
              onSearch={onSearch}
              loading={loading}
              onSelectIcon={handleIconChange}
            />
          </ThemedIconContainer>
        </div>
      </div>
    </div>
  );
};

export default FirstColumn;

// <hr className="ColumnDivider" />
//       <div>
//         <div className="PostsType">
//           <strong>Posts Type</strong>
//           <div className="PostsTypeCheck">
//             <CheckmarkButton
//               isactive={checkedButtons.text}
//               onClick={() => handleToggle("text")}
//             >
//               Text Only
//             </CheckmarkButton>
//             <CheckmarkButton
//               isactive={checkedButtons.images}
//               onClick={() => handleToggle("images")}
//             >
//               Images
//             </CheckmarkButton>
//             <CheckmarkButton
//               isactive={checkedButtons.videos}
//               onClick={() => handleToggle("videos")}
//             >
//               Videos
//             </CheckmarkButton>
//             <CheckmarkButton
//               isactive={checkedButtons.links}
//               onClick={() => handleToggle("links")}
//             >
//               Hyper Links
//             </CheckmarkButton>
//           </div>
//         </div>
//       </div>
