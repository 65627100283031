import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/authentication/AuthContext/AuthContext";
import { OrganizationProvider } from "./AdminPanel/getUserContext.js";
import { ChannelProvider } from "./AdminPanel/getChannelContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <ChannelProvider>
      <OrganizationProvider>
        <App />
      </OrganizationProvider>
    </ChannelProvider>
  </AuthProvider>
);

const link = document.createElement("link");
link.href = "https://fonts.googleapis.com/icon?family=Material+Icons";
link.rel = "stylesheet";
document.head.appendChild(link);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
