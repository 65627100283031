import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { defaultColumn } from "./BookmarkTab";
import { useBookmarks } from "./BookmarkContext";
import CopyPost from "./CopyPost";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faFileWord } from "@fortawesome/free-regular-svg-icons";
import ShareButtons from "./ShareButtons";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  height: 90vh;
  background-color: ${({ theme }) => theme.popup_background};
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    max-width: 90%;
  }
`;

const WidgetContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  overflow-y: hidden;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const BookmarksContainer = styled.div`
  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  margin-right: 30px;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  z-index: 1000;
`;

const ExportButton = styled.button`
  background-color: ${({ theme }) => theme.button_background};
  color: ${({ theme }) => theme.button_text};
  width: 100px;
  border-radius: 4px;
  border-color: none;
  padding: 10px 22px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #00a2ed;
    color: #ffff;
    border-color: #000;
  }
`;

// const SendButton = styled.button`
//   background-color: ${({ theme }) => theme.button_background};
//   color: ${({ theme }) => theme.button_text};
//   width: 80px;
//   border-radius: 4px;
//   padding: 10px 24px;
//   gap: 8px;
//   display: flex;
//   border: none;
//   align-items: center;
//   justify-content: center;
//   margin: auto auto;
//   font-size: 15px;
//   cursor: pointer;
//   transition: all 0.3s ease;

//   &:hover {
//     background-color: ${({ platform }) => {
//       switch (platform) {
//         case "whatsapp":
//           return "#25D366";
//         case "facebook":
//           return "#3b5998";
//         case "twitter":
//           return "#1DA1F2";
//         default:
//           return "#799351";
//       }
//     }};
//     color: #ffff;
//     border-color: #000;
//   }
//   .fa-whatsapp,
//   .fa-facebook,
//   .fa-twitter {
//     font-size: 18px;
//   }
// `;

const ButtonContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 20px;
    bottom: 10px;
  }
`;

const StyledQuillContainer = styled.div`
  .ql-container {
    width: 100%;
    height: 350px;
    border: 1px solid ${({ theme }) => theme.border_color};
    border-radius: 4px;

    @media (max-width: 768px) {
      height: 200px;
    }
  }

  /* Targeting text color for buttons, labels, and picker items */
  .ql-toolbar .ql-picker-label,
  .ql-toolbar .ql-picker-item,
  .ql-toolbar button {
    color: ${({ theme }) => theme.text};
  }

  /* Targeting SVG icons within buttons */
  .ql-toolbar button svg,
  .ql-toolbar .ql-picker-label svg,
  .ql-toolbar .ql-picker-item svg {
    fill: ${({ theme }) => theme.text};
  }

  /* Targeting active and hover states for buttons */
  .ql-toolbar button.ql-active,
  .ql-toolbar button:hover {
    color: ${({ theme }) => theme.textHover}; /* Adjust as needed */
    svg {
      fill: ${({ theme }) => theme.textHover}; /* Adjust as needed */
    }
  }

  /* Additional targeting for other toolbar elements that might have been missed */
  .ql-toolbar .ql-stroke {
    stroke: ${({ theme }) => theme.text}; /* For SVG strokes */
  }

  .ql-toolbar .ql-fill {
    fill: ${({ theme }) => theme.text}; /* For SVG fills */
  }
`;

const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 96%;
  margin: 0 auto;
`;

function BookmarkEditPopup({ closePopup }) {
  const { bookmarkedPosts } = useBookmarks();
  const [searchTerm, setSearchTerm] = useState("");

  const [textInput, setTextInput] = useState(() => {
    const savedText = localStorage.getItem("textInput");
    return savedText !== null ? savedText : "";
  });

  useEffect(() => {
    localStorage.setItem("textInput", textInput);
  }, [textInput]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown

      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ align: [] }], // text align
      ["clean"], // remove formatting
    ],
  };

  // Function to handle exporting the TextInput content to a Word file
  const exportToWord = () => {
    const header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Word Document</title></head><body>`;
    const footer = `</body></html>`;
    // Replace line breaks in textInput with <br> tags for proper HTML formatting
    const formattedTextInput = textInput.replace(/\n/g, "<br>");
    const sourceHTML = header + formattedTextInput + footer;

    const source =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(sourceHTML);
    const fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "Teledeck.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  const handleSendInput = (platform) => {
    // Create a new DOM element
    const tempDiv = document.createElement("div");
    // Set its HTML content to the input text
    tempDiv.innerHTML = textInput;
    // Use the element's textContent property to get the text without HTML tags
    let cleanedInput = tempDiv.textContent || tempDiv.innerText || "";

    // Replace newlines with the WhatsApp break
    const encodedMessage = encodeURIComponent(cleanedInput).replace(
      /%0A/g,
      "%0A"
    );

    let shareUrl = "";

    if (platform === "whatsapp") {
      shareUrl = `https://wa.me/?text=${encodedMessage}`;
    } else if (platform === "facebook") {
      const currentURL = encodeURIComponent(window.location.href);
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}&quote=${encodedMessage}`;
    } else if (platform === "twitter") {
      // Twitter sharing URL format
      shareUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
    }

    window.open(shareUrl, "_blank");
  };

  const handleTextInput = (textInput) => {
    // Create a temporary div to use for stripping HTML and decoding entities
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = textInput;
    // Extract text content, which strips tags and decodes entities
    let cleanedInput = tempDiv.textContent || tempDiv.innerText || "";
    // Remove invisible characters (e.g., zero-width spaces) if necessary
    cleanedInput = cleanedInput.replace(/[\u200B-\u200D\uFEFF]/g, "");
    // Trim the result to remove leading and trailing spaces
    cleanedInput = cleanedInput.trim();
    return cleanedInput;
  };

  const filteredPosts = useMemo(() => {
    return bookmarkedPosts.filter((post) => {
      return post.text.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [bookmarkedPosts, searchTerm]);

  return (
    <Background>
      <PopupContainer onClick={(event) => event.stopPropagation()}>
        <CloseButton onClick={closePopup}>
          <span className="material-icons">close</span>
        </CloseButton>

        <BookmarksContainer>
          <h3>Bookmarked Posts</h3>
          <div className="input-icon-container">
            <ThemedInput
              type="text"
              className="ColumnNameInput TextArea"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span className="material-icons search-icon">search</span>
          </div>
          {filteredPosts.length > 0 ? (
            filteredPosts.map((post) => (
              <>
                <div key={post.post_id}>
                  <CopyPost
                    post={post}
                    column={defaultColumn}
                    is_a_bookmark={true}
                  />
                </div>
              </>
            ))
          ) : (
            <p style={{ marginLeft: "10px" }}>No bookmarks yet.</p>
          )}
        </BookmarksContainer>
        <WidgetContainer>
          <h3>Bookmark Edit</h3>
          <StyledQuillContainer>
            <ReactQuill
              value={textInput}
              onChange={setTextInput}
              modules={modules}
              theme="snow"
            />
          </StyledQuillContainer>
          <ButtonContainer>
            <ExportButton onClick={exportToWord}>
              Export{""}
              <FontAwesomeIcon icon={faFileWord} size="25px" />
            </ExportButton>
            <p style={{ width: "8rem", textAlign: "center" }}>
              Or Share Using:
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "20px",
              }}
            >
              {/* <SendButton
                platform={"whatsapp"}
                onClick={() => handleSendInput("whatsapp")}
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </SendButton>
              <SendButton
                platform={"facebook"}
                onClick={() => handleSendInput("facebook")}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </SendButton>
              <SendButton
                platform={"twitter"}
                onClick={() => handleSendInput("twitter")}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </SendButton> */}
              <ShareButtons
                text={handleTextInput(textInput)}
                shareUrl={window.location.href}
              />
            </div>
          </ButtonContainer>
        </WidgetContainer>
      </PopupContainer>
    </Background>
  );
}

export default BookmarkEditPopup;
