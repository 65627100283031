import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "./components/themes/ThemeContext";
import SideBar from "./components/navbar/SideBar.js";
import GlobalStyle from "./global/GlobalStyle.js";
import { EventSourceProvider } from "./context/EventSourceContext";
import SignIn from "./components/authentication/SignIn/SignIn.js";
import SignUp from "./components/authentication/SignUp/SignUp.js";
// import Verify from "./components/authentication/VerifyEmail/Verify.js";
import ResetPassword from "./components/authentication/PasswordReset/passResetForm.js";
import { ColumnsProvider } from "./components/ColumnsContext";
import ColumnsContainer from "./components/ColumnsContainer";
import { useAuth } from "./components/authentication/AuthContext/AuthContext.js";
import { openDb } from "./components/localdb/DBUtils.js";
import LoadingSpinner from "./components/LoadingSpinner.js";
import GetOrg from "./AdminPanel/GetOrg.js";
import GetOrgUsersDataTables from "./AdminPanel/OrgUsers.js";
import AddUserOrgPage from "./AdminPanel/addUserOrg.js";
import GetAllUsers from "./AdminPanel/users.js";
import GetUsersInOrgDataTables from "./business/home.js";
import GetRequestedUsers from "./business/viewRequest.js";
import ChannelRequestsTables from "./AdminPanel/channelRequests.js";
import GetChannelsDataTables from "./AdminPanel/channels.js";
import ChannelTagsDataTables from "./AdminPanel/getTagsForChannel.js";
import TagsDataTables from "./AdminPanel/getAllTags.js";
import AddUserToOrg from "./AdminPanel/addUserToOrg.js";
import Map from "./components/map/Map.js";

function App() {
  const { isAuthenticated, role, isLoading } = useAuth();

  document.addEventListener("DOMContentLoaded", function () {
    openDb()
      .then(() => {})
      .catch((error) => {
        console.error("Error initializing database:", error);
      });
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const ProtectedRoute = ({ children, onlyVerified }) => {
    // Redirect unauthenticated users to the sign-in page
    if (!isAuthenticated) {
      return <Navigate to="/signin" replace />;
    }
    // Redirect users who are authenticated but not verified to the verify page if onlyVerified flag is true
    // if (onlyVerified && !isVerified) {
    //   return <Navigate to="/verify" replace />;
    // }
    return children;
  };

  const ProtectedAuthRoutes = ({ children, onlyVerified }) => {
    if (isAuthenticated) {
      if (role === "admin") {
        return <Navigate to="/admin/users" replace />;
      } else if (role === "business") {
        return <Navigate to="/business/home" replace />;
      }

      return <Navigate to="/" replace />;
    }

    // Redirect users who are authenticated but not verified to the verify page if onlyVerified flag is true
    // if (onlyVerified && !isVerified) {
    //   return <Navigate to="/verify" replace />;
    // }

    return children;
  };

  const AdminRoutes = ({ children }) => {
    if (role === "user") {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  // In your App component's return statement, update the route setup:
  return (
    <ThemeProvider>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute onlyVerified>
                  <InnerApp />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/map"
              element={
                <ProtectedRoute onlyVerified>
                  <Map />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/signin"
              element={
                <ProtectedAuthRoutes>
                  <SignIn />
                </ProtectedAuthRoutes>
              }
            />
            <Route
              path="/signup"
              element={
                <ProtectedAuthRoutes>
                  <SignUp />
                </ProtectedAuthRoutes>
              }
            />
            {/* <Route path="/verify" element={<ProtectedRoute><Verify /></ProtectedRoute>}/> */}
            <Route
              path="/reset-password"
              element={
                <ProtectedAuthRoutes>
                  <ResetPassword />
                </ProtectedAuthRoutes>
              }
            />
            {/* <Route path="/bundles" element={<Bundle />} /> */}

            <Route
              path="/admin/*"
              element={
                <AdminRoutes>
                  <AdminLayout />
                </AdminRoutes>
              }
            />
            <Route
              path="/business/*"
              element={
                <AdminRoutes>
                  <BusinessLayout />
                </AdminRoutes>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const AdminLayout = () => {
  return (
    <Routes>
      <Route path="GetOrg" element={<GetOrg />} />
      <Route path="OrgUsers" element={<GetOrgUsersDataTables />} />
      <Route path="adduser" element={<AddUserOrgPage />} />
      <Route path="users" element={<GetAllUsers />} />
      <Route path="channelRequestes" element={<ChannelRequestsTables />} />
      <Route path="channels" element={<GetChannelsDataTables />} />
      <Route path="GetTags" element={<ChannelTagsDataTables />} />
      <Route path="getAllTags" element={<TagsDataTables />} />
      <Route path="addUserToOrg" element={<AddUserToOrg />} />
    </Routes>
  );
};

const BusinessLayout = () => {
  return (
    <Routes>
      <Route path="viewRequest" element={<GetRequestedUsers />} />
      <Route path="home" element={<GetUsersInOrgDataTables />} />
    </Routes>
  );
};

const InnerApp = () => {
  return (
    <>
      <EventSourceProvider>
        <GlobalStyle />
        <ColumnsProvider>
          <SideBar>
            <ColumnsContainer />
          </SideBar>
        </ColumnsProvider>
      </EventSourceProvider>
    </>
  );
};

export default App;
