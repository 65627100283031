import React, { useEffect, useState, useContext, useCallback } from "react";
import debounce from "lodash.debounce";
import Navbar from "./navbar";
import "./css/DataTables.css";
import {
  getTagsForChannel,
  getAllTags,
  addTagForChannel,
  deleteTagFromChannel,
} from "./utils/UserUtil";
import ChannelContext from "./getChannelContext";
import Modal from "./modal";
import { Form, Label, Input, Button } from "./css/StyledComponents.js";

const ChannelTagsDataTables = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { channelId } = useContext(ChannelContext);
  const { channelName } = useContext(ChannelContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchData();
  }, [channelId]);

  const fetchData = async () => {
    try {
      const data = await getTagsForChannel(channelId);
      setUsersData(data);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setUsersData([]);
    }
  };

  const fetchTags = async (search) => {
    // Ensure this logs correctly
    try {
      const tags = await getAllTags(search);
      // Check the received data
      setAllTags(tags);
    } catch (error) {
      console.error("Failed to fetch tags:", error);
    }
  };

  const debouncedFetchTags = useCallback(
    debounce((search) => fetchTags(search), 400),
    []
  );

  const handleInputChange = (e) => {
    const search = e.target.value;
    setFilter(search);
    debouncedFetchTags(search);
  };

  const handleSelectChange = (e) => {
    const selectedTag = e.target.value;
    setFilter(selectedTag);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await addTagForChannel(channelId, filter);
      setModalOpen(false);
      fetchData();
    } catch (error) {
      console.error("Failed to add tag:", error);
    }
  };
  const handleDeleteTag = async (tagId) => {
    try {
      await deleteTagFromChannel(channelId, tagId);
      fetchData();
    } catch (error) {
      console.error("Failed to delete tag:", error);
    }
  };

  const buttonStyle = {
    backgroundColor: "#157e18",
    border: "none",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "15px",
    margin: "4px 1px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "10%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  };
  const buttonStyle2 = {
    backgroundColor: "red",
    border: "none",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "15px",
    margin: "4px 1px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "10%",
    height: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    gap: "10px",
  };

  return (
    <div>
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>{channelName}</h1>
          <button onClick={() => setModalOpen(true)} style={buttonStyle}>
            Add Tag
          </button>
          <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            <Form onSubmit={handleFormSubmit}>
              <Input id="channelId" type="text" value={channelId} hidden />
              <Label htmlFor="tagName">Tag Name:</Label>
              <Input
                type="text"
                value={filter}
                onChange={handleInputChange}
                placeholder="Filter tags..."
              />
              <select id="tagName" onChange={handleSelectChange}>
                {allTags.map((tag) => (
                  <option key={tag.id} value={tag.name} defaultValue="lbne">
                    {tag.name}
                  </option>
                ))}
              </select>
              <Button type="submit">Save Changes</Button>
            </Form>
          </Modal>
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Remove Tag</th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>
                  <button
                    style={buttonStyle2}
                    onClick={() => handleDeleteTag(item.id)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </td>
              </tr>
            ))}
            {usersData.length === 0 && (
              <tr>
                <td colSpan="4">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChannelTagsDataTables;
