import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import FirstColumn from "./popup-elements/FirstColumn";
import SecondColumn from "./popup-elements/SecondColumn";
import ThirdColumn from "./popup-elements/ThirdColumn";
import Settings from "../settings/Settings";
import { fetchIcons } from "../utils/iconSearchUtil";
import debounce from "lodash/debounce";
import "../../assets/css/Popup.css";

const PopupContainer = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  box-shadow: 0px 0px 10px ${({ theme }) => theme.popup_shadow};
`;

const Popup = ({ onClose, type }) => {
  const [checkedButtons, setCheckedButtons] = useState({
    text: true,
    images: true,
    videos: true,
    links: true,
  });

  const [iconOptions, setIconOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [chosenChannels, setChosenChannels] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    const loadIcons = async (query = "") => {
      const icons = await fetchIcons(query);
      setIconOptions(icons);
    };

    loadIcons();
  }, []);

  const debouncedFetchIcons = useCallback(
    debounce(async (query) => {
      setLoading(true);
      const icons = await fetchIcons(query);
      setIconOptions(icons);
      setLoading(false);
    }, 300),
    []
  );

  const handleSearch = (query) => {
    setLoading(true);
    debouncedFetchIcons(query);
  };

  const handleToggle = (type) => {
    setCheckedButtons({
      ...checkedButtons,
      [type]: !checkedButtons[type],
    });
  };

  const handleAddChannel = (channel) => {
    setAvailableChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setChosenChannels((prev) => [...prev, channel]);
  };

  const handleRemoveChannel = (channel) => {
    setChosenChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setAvailableChannels((prev) => [channel, ...prev]);
  };

  const handleIconChange = (icon) => {
    setSelectedIcon(icon);
  };

  return (
    <PopupContainer className="PopupContainer">
      {type === "newColumn" && (
        <div className="ColumnContainer">
          <div>
            <h2 className="popupheader">Add Column</h2>
            <hr className="SectionDivider" />
          </div>
          <div className="ColumnElements">
            <FirstColumn
              checkedButtons={checkedButtons}
              handleToggle={handleToggle}
              iconOptions={iconOptions}
              onSearch={handleSearch}
              loading={loading}
              onIconChange={handleIconChange}
            />
              <SecondColumn
                checkedButtons={checkedButtons}
                handleToggle={handleToggle}
                channels={availableChannels}
                onAddChannel={handleAddChannel}
                chosenChannels={chosenChannels}
                setChannels={setAvailableChannels}
              />
              <ThirdColumn
                onClose={onClose}
                chosenChannels={chosenChannels}
                onRemoveChannel={handleRemoveChannel}
                selectedIcon={selectedIcon}
              />
          </div>
        </div>
      )}
      {type === "settings" && <Settings onClose={onClose} />}
    </PopupContainer>
  );
};

export default Popup;
