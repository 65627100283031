import React, { useEffect, useState, useContext } from "react";
import Navbar from "./navbar";
import "./css/DataTables.css";
import { getUsers } from "./utils/UserUtil";
import OrganizationContext from "./getUserContext";

const UsersDataTables = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    fetchData();
  }, [organizationId]);

  const fetchData = async () => {
    if (!organizationId) return; // Exit early if organizationId is not available

    try {
      const data = await getUsers(organizationId);
      setUsersData(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setUsersData({ data: [], total: 0 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>Users List</h1>
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Registered</th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((item) => (
              <tr key={item.id}>
                <td>{`${item.first_name || "N/A"} ${
                  item.last_name || "N/A"
                }`}</td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                <td>{item.created_at.split("T")[0]}</td>
              </tr>
            ))}
            {usersData.length === 0 && (
              <tr>
                <td colSpan="4">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersDataTables;
