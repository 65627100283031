import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { handleLogout } from "../components/authentication/Logout/Logout_utils";

const Nav = styled.nav`
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.nav_background};
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;
  @media (max-width: 768px) {
    position: static;
    height: auto;
    width: 100%;
    padding-top: 0px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Li = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.1s linear;

  &:hover {
    background-color: #575757;
  }

  span {
    margin-right: 10px; // Adjusts space between icon and text
    font-size: 24px; // Adjusts icon size
  }
`;

const LogoutLi = styled.li`
  color: white;
  text-decoration: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.1s linear;
  margin-top: auto;
  padding-bottom: 50px;
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  @media (max-width: 768px) {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 2;

    span {
      font-size: 30px;
      border: ${({ open }) => (open ? "none" : "2px solid black")};
      border-radius: 3px;
      color: ${({ open }) => (open ? "white" : "black")};
    }
  }
`;

const Navbar = () => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <MenuButton open={open} onClick={() => setOpen(!open)}>
        <span className="material-symbols-outlined">
          {" "}
          {open ? "close" : "menu"}
        </span>
      </MenuButton>
      <Nav open={open}>
        <Ul>
          <Li>
            <StyledLink to="/admin/users">
              <span className="material-symbols-outlined">group</span>Users
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/admin/OrgUsers">
              <span className="material-symbols-outlined">group</span>
              Organization
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/admin/adduser">
              <span className="material-symbols-outlined">person_add</span>Add
              Organization
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/admin/addUserToOrg">
              <span className="material-symbols-outlined">group</span> Assign
              User to Org
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/admin/channels">
              <span className="material-symbols-outlined">stacks</span>Channels
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/admin/GetAllTags">
              <span className="material-symbols-outlined">tag</span>Tags
            </StyledLink>
          </Li>

          <Li>
            <StyledLink to="/admin/channelRequestes">
              <span className="material-symbols-outlined">publish</span>
              Requested Channels
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/">
              <span className="material-symbols-outlined">home</span>Home
            </StyledLink>
          </Li>
        </Ul>
        <LogoutLi>
          <StyledLink onClick={handleLogout}>
            <span className="material-symbols-outlined">logout</span>Logout
          </StyledLink>
        </LogoutLi>
      </Nav>
    </>
  );
};

export default Navbar;
