import React, { useEffect, useState, useContext, useCallback } from "react";
import debounce from "lodash.debounce";
import Navbar from "./navbar";
import "./css/DataTables.css";
import { getAllTags, addTag, deleteTag } from "./utils/UserUtil";
import Modal from "./modal2";
import { Form, Label, Input, Button } from "./css/StyledComponents.js";

const TagsDataTables = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getAllTags();
      setUsersData(data);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setUsersData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const tags = await getAllTags();
      setAllTags(tags);
    } catch (error) {
      console.error("Failed to fetch tags:", error);
    }
  };

  const handleInputChange = (e) => {
    setSelectedTag(e.target.value);
    debouncedFetchTags(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await addTag(selectedTag);
      setModalOpen(false);
      fetchData();
    } catch (error) {
      console.error("Failed to add tag:", error);
    }
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await deleteTag(tagId);
      fetchData();
    } catch (error) {
      console.error("Failed to delete tag:", error);
    }
  };

  const debouncedFetchTags = useCallback(
    debounce((search) => fetchTags(search), 400),
    []
  );

  const buttonStyle = {
    backgroundColor: "#157e18",
    border: "none",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "flex",
    fontSize: "15px",
    margin: "4px 1px",
    cursor: "pointer",
    borderRadius: "5px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    width: "10%",
    height: "100%",
  };

  return (
    <div>
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>Tags List</h1>
          <button onClick={() => setModalOpen(true)} style={buttonStyle}>
            Add Tag
          </button>
          {isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
              <Form onSubmit={handleFormSubmit}>
                <Label htmlFor="tagName">Tag Name:</Label>
                <Input
                  type="text"
                  value={selectedTag}
                  onChange={handleInputChange}
                  placeholder="Filter tags..."
                />
                <Button type="submit">Save Changes</Button>
              </Form>
            </Modal>
          )}
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Remove Tag</th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>
                  <button
                    style={{ ...buttonStyle, backgroundColor: "red" }}
                    onClick={() => handleDeleteTag(item.id)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </td>
              </tr>
            ))}
            {usersData.length === 0 && (
              <tr>
                <td colSpan="2">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TagsDataTables;
