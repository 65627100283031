import React, { useContext, useEffect, useRef, useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import styled from "styled-components";
import { EventSourceContext } from "../context/EventSourceContext";
import Post from "./post/Post";
import Popup from "./post/popup";
import "../assets/css/Popup.css";
import { updateColumnInDB } from "./localdb/DBColumn"; // Import update function
import { website } from "../globalUtils";
import { updateColumnsToAPIChips } from "./utils/ColumnUtils";
import EditPopup from "./EditPopup";
import { getPostsFromApi } from "./utils/postUtils";
import { useColumns } from "./ColumnsContext";
import calendarIcon from "../assets/images/calendar.svg";
import whitecalendarIcon from "../assets/images/whiteCalendar.svg";
import { useTheme } from "./themes/ThemeContext";
import { set } from "lodash";

const Column = styled.div`
  background-color: ${({ theme }) => theme.column_background};
  margin-left: 10px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: calc(100% - 10px);
  color: ${({ theme }) => theme.column_text};
`;

const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 100%;
  margin: 0 auto;
`;

const ColumnBody = styled.div`
  width: 100%;
  position: relative;
  height: calc(100% - 50px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: background-color 0.3s linear;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.border_color};
      transition: background-color 0.3s linear;
    }
  }
`;

const ColumnHeader = styled.div`
  box-sizing: border-box;
  padding: 10px;
  background-color: ${({ theme }) => theme.filter_background};
  width: 300px;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColumnHeaderText = styled.div`
  font-size: 16px;
  text-align: center;
  width: 150px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnHeaderIcon = styled.i`
  font-size: 24px;
`;

const ColumnHeaderIconClick = styled.i`
  font-size: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.87;
  }
`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    flex: 0 0 calc(50% - 10px);
    margin: 5px;
    box-sizing: border-box;
  }
`;

const FiltersDropDown = styled.div`
  background-color: ${({ theme }) => theme.filter_background};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  top: 0px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  gap: 10px;
  margin-bottom: 2px;
`;

const Chip = styled.div`
  padding: 6px 13px;
  margin-right: 5px;
  border-radius: 16px;
  background-color: ${({ $active, theme }) =>
    $active ? theme.chip_active : theme.chip_inactive};
  color: ${({ theme }) => theme.column_text};
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  text-align: center;
  &:hover {
    opacity: 0.87;
  }
`;

const EditButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  padding: 10px;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.87;
  }
`;

const LoadButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  padding: 10px;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.87;
  }
`;
const SearchHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.filter_background};
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
`;

const DatePicker = styled.input`
  width: 82%;
  padding: 4px 40px 4px 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};

  &::-webkit-calendar-picker-indicator {
    opacity: 1;
    font-size: 20px;
    background-image: ${({ $theme }) =>
      $theme === "light"
        ? `url(${calendarIcon})`
        : `url(${whitecalendarIcon})`};

    background-size: cover;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  }
`;

const DraggableColumn = ({ static_column, index, globalSearch }) => {
  const { subscribe, unsubscribe } = useContext(EventSourceContext);
  const [localMessages, setLocalMessages] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [column, setColumn] = useState(static_column);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [isEditPopupVisible, setEditPopupVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentsender, setCurrentSender] = useState("");
  const [currentId, setCurrentPost] = useState("");
  const { updateColumn } = useColumns();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [firstInteractionTime, setFirstInteractionTime] = useState(
    new Date().toLocaleString()
  );
  const [dateValue, setDateValue] = useState("");
  const { theme } = useTheme();
  const columnRef = useRef();

  const handleDateChange = (event) => {
    const localDate = event.target.value.toLocaleString();
    setDateValue(localDate);
  };

  const updateTimestamp = () => {
    const utcDate = new Date();
    const localDate = utcDate.toLocaleString();
    setFirstInteractionTime(localDate);
  };

  useEffect(() => {
    const utcDate = new Date();
    const localDate = utcDate.toLocaleString();
    setFirstInteractionTime(localDate);
  }, []);

  const loadMorePosts = async () => {
    if (page < totalPages && !loading) {
      setLoading(true);
      const nextPage = page + 1;

      const result = await getPostsFromApi({
        column: column,
        search: debouncedValue,
        page: nextPage,
        limit: 10,
        maxDate: dateValue
          ? new Date(dateValue).toLocaleString()
          : firstInteractionTime,
      });

      if (result.data && result.data.length > 0) {
        setLocalMessages((prevMessages) => [...prevMessages, ...result.data]);
        setPage(nextPage);
      }
      setTotalPages(result.totalPages || totalPages);
      setLoading(false);
    } else {
    }
  };

  useEffect(() => {
    if (globalSearch) setDebouncedValue(globalSearch);
  }, [globalSearch]);

  useEffect(() => {
    const handleMessage = (newMessage) => {
      column.channels.forEach(function (channel) {
        if (channel.id === newMessage.sender_id) {
          if (!newMessage.text.includes(debouncedValue)) return;

          if (column.get_image && !newMessage.has_image) {
            return;
          }
          if (column.get_preview === 1 && !newMessage.has_preview) {
            return;
          }
          if (column.get_video && !newMessage.has_video) {
            return;
          }
          if (
            column.get_text_only &&
            (newMessage.has_image ||
              newMessage.has_video ||
              newMessage.has_preview)
          ) {
            return;
          }

          newMessage.chat_title = channel.chat_title;
          newMessage.sender_username = channel.sender_username;
          newMessage.image = `${website}${channel.image}`;
          newMessage.isApi = true;
          newMessage.id = newMessage.sender_id + "" + newMessage.post_id;
          setLocalMessages((prevMessages) => [newMessage, ...prevMessages]);
        }
      });
    };

    subscribe(handleMessage);
    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, column.content, inputValue, column]);

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  useEffect(() => {
    const fetchColumn = async () => {
      try {
        setColumn(static_column);
      } catch (error) {
        console.error("Failed to fetch column:", error);
      }
    };

    fetchColumn();
  }, [static_column]);

  const handleOpenPopup = (sender_username, post_id) => {
    setCurrentSender(sender_username);
    setCurrentPost(post_id);
    setIsOpen(true);
  };

  const handlePopupEdit = async (column2, type) => {
    // Set the state of the clicked chip and reset others

    const updatedColumn = {
      ...column2,

      channels: column2.channels,

      get_text_only: 0,

      get_image: 0,

      get_video: 0,

      get_preview: 0,
    };

    // Toggle the state of the clicked chip

    updatedColumn[type] = column2[type] ? 0 : 1;

    try {
      setColumn(updatedColumn);

      setForceUpdate((f) => !f); // Toggle forceUpdate to trigger re-render
    } catch (error) {
      console.error("Failed to update column:", error);
    }
  };

  const handleChipClick = async (chipType) => {
    updateTimestamp();
    const updates = {
      get_text_only: 0,
      get_image: column.get_image, // Keep current state
      get_video: column.get_video, // Keep current state
    };

    // Adjust logic for handling chip clicks
    switch (chipType) {
      case "textOnly":
        updates.get_text_only = column.get_text_only ? 0 : 1;
        updates.get_image = 0; // Reset others
        updates.get_video = 0; // Reset others
        break;
      case "images":
        if (column.get_image && column.get_video) {
          // If both are active, deactivate both
          updates.get_image = 0;
          updates.get_video = 0;
        } else {
          // Otherwise, toggle the state of images
          updates.get_image = column.get_image ? 0 : 1;
        }
        break;
      case "videos":
        if (column.get_image && column.get_video) {
          // If both are active, deactivate both
          updates.get_image = 0;
          updates.get_video = 0;
        } else {
          // Otherwise, toggle the state of videos
          updates.get_video = column.get_video ? 0 : 1;
        }
        break;
      case "preview":
        updates.get_preview = column.get_preview ? 0 : 1;
        break;
      default:
        break;
    }

    const updatedColumn = {
      ...column,
      ...updates,
    };

    setColumn(updatedColumn);
    setForceUpdate((f) => !f); // Toggle forceUpdate to trigger re-render

    try {
      await updateColumnInDB(column.id, updatedColumn);
    } catch (error) {
      console.error("Failed to update column in DB:", error);
    }

    try {
      await updateColumnsToAPIChips(updatedColumn);
    } catch (error) {
      console.error("Failed to update column in API:", error);
    }

    updateColumn(updatedColumn);
  };

  const renderChips = () => {
    return (
      <>
        <Chip
          $active={column.get_text_only === 1}
          onClick={() => handleChipClick("textOnly")}
        >
          Text Only
        </Chip>
        <Chip
          $active={column.get_image === 1}
          onClick={() => handleChipClick("images")}
        >
          Images
        </Chip>
        <Chip
          $active={column.get_video === 1}
          onClick={() => handleChipClick("videos")}
        >
          Videos
        </Chip>
        <Chip
          $active={column.get_preview === 1}
          onClick={() => handleChipClick("preview")}
        >
          Preview
        </Chip>
      </>
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleInputChange = (event) => {
    updateTimestamp();
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      let date = new Date(dateValue);
      date.setHours(date.getHours() - 1);
      let newDateValue = date.toLocaleString();

      const posts = await getPostsFromApi({
        column: column,
        search: debouncedValue,
        page: 1,
        limit: 10,
        maxDate: dateValue ? newDateValue : firstInteractionTime,
      });
      // posts.data.forEach((post) => {
      //   column.channels.forEach((channel) => {
      //     if (channel.id === post.sender_id) {
      //       post.image = `${website}${channel.image}`;
      //     }
      //   });
      // });
      setLocalMessages(posts.data);
      setPage(2);
      setTotalPages(posts.totalPages || 1);
    };

    fetchPosts();
  }, [debouncedValue, column, forceUpdate, dateValue, globalSearch]);

  const toggleEditPopup = () => setEditPopupVisible(!isEditPopupVisible);

  return (
    <Draggable key={column.id} draggableId={column.id.toString()} index={index}>
      {(provided) => (
        <Column
          key={column.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <ColumnHeader>
            <ColumnHeaderIcon className="material-icons">
              {column.icon}
            </ColumnHeaderIcon>{" "}
            <ColumnHeaderText {...provided.dragHandleProps}>
              {column.name}
            </ColumnHeaderText>
            <ColumnHeaderIconClick
              className="material-icons"
              onClick={toggleDropdown}
            >
              more_vert
            </ColumnHeaderIconClick>
          </ColumnHeader>
          {isDropdownVisible && (
            <FiltersDropDown>
              <SearchHeader>
                <div className="input-icon-container">
                  <ThemedInput
                    type="text"
                    className="DraggableColumnNameInput TextArea"
                    placeholder="Search..."
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <span className="material-icons search-icon">search</span>
                </div>
                <DatePicker
                  type="datetime-local"
                  onChange={handleDateChange}
                  value={dateValue}
                  $theme={theme}
                />
              </SearchHeader>
              <ChipContainer>{renderChips()}</ChipContainer>
              <EditButton onClick={toggleEditPopup}>Edit Column</EditButton>
            </FiltersDropDown>
          )}
          <ColumnBody ref={columnRef}>
            {localMessages.map((msg, index) => (
              <div key={index} data-msg-index={index}>
                <Post
                  column={column}
                  post={msg}
                  onOpenPopup={handleOpenPopup}
                />
              </div>
            ))}
            {isOpen && (
              <Popup
                senderusername={currentsender}
                post_id={currentId}
                onClose={() => setIsOpen(false)}
              />
            )}
            {page < totalPages && !loading && (
              <LoadButton onClick={loadMorePosts}>Load More</LoadButton>
            )}
          </ColumnBody>

          {isEditPopupVisible && (
            <EditPopup
              column={column}
              onClose={toggleEditPopup}
              onSave={handlePopupEdit}
            />
          )}
        </Column>
      )}
    </Draggable>
  );
};

export default DraggableColumn;
