import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import { getAuth, post } from "../globalUtils";

const AddUserToOrg = () => {
  const [email, setEmail] = useState("");
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [organization, setOrganization] = useState("");
  const [errors, setErrors] = useState({});

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    console.log("org is", organization);
  }, [organization]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await getAuth("/organization/names");
      const data = await response.json();
      console.log(data.data);
      setOrganizationOptions(data.data);
    };
    fetchOrganizations();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !organization) {
      alert("Please fill in all fields");
      return;
    }
    const response = await post("/organization/add", {
      email: email,
      organization_id: organization,
    });
    if (response.ok) {
      alert("User added to organization successfully");
    } else {
      const data = await response.json();
      alert(data.message);
    }
  };

  return (
    <div className="form-container-1">
      <Navbar />
      <form onSubmit={handleSubmit} className="form-1">
        <div className="input-group-1">
          <label htmlFor="name" className="label-1">
            User Email:
          </label>
          <input
            className="input-1"
            type="text"
            id="email"
            value={email}
            name="email"
            onChange={handleEmailChange}
          />
        </div>
        <div className="input-group-1">
          <label htmlFor="organization" className="label-1">
            Organization:
          </label>
          <select
            className="input-1"
            id="organization"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
          >
            {organizationOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddUserToOrg;
