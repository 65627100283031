import React, { createContext, useState } from "react";

const ChannelContext = createContext(null);

export const ChannelProvider = ({ children }) => {
  const [channelId, setChannelId] = useState("");
  const [channelName, setChannelName] = useState("");
  const setChannelsId = (id) => {
    setChannelId(id);
  };
  const setChannelsName = (name) => {
    setChannelName(name);
  };

  return (
    <ChannelContext.Provider
      value={{ channelId, setChannelsId, channelName, setChannelsName }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export default ChannelContext;
