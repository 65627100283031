import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import DraggableColumn from "./DraggableColumn";
import styled, { keyframes } from "styled-components";
import { useColumns } from "./ColumnsContext";
import { Element } from "react-scroll";

const Container = styled.div`
  display: flex;
  height: ${({ showSearchPopup }) => (showSearchPopup ? "95vh" : "100vh")};
  overflow-x: auto;

  background-color: ${({ theme }) => theme.background};
  padding: 10px;
  padding-left: 0px;
  box-sizing: border-box;

  /* Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const slideDown = keyframes`
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
`;

const SearchIcon = styled.span`
  position: absolute;
  left: 10px;
  background: transparent;
  color: ${({ theme }) => theme.text};
  animation: ${slideDown} 0.5s ease-out forwards;
`;

const ThemedInput = styled.input`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 20%;
  margin: 0 auto;
`;

const ColumnsContainer = () => {
  // Use columns and reorderColumns from context
  const { columns, reorderColumns } = useColumns();
  const [globalSearch, setGlobalSearch] = useState("");
  const [showSearchPopup, setShowSearchPopup] = useState(true);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    reorderColumns(result.source.index, result.destination.index);
  };

  const handleSearch = (e) => {
    setGlobalSearch(e.target.value);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === " ") {
        e.preventDefault();
        setShowSearchPopup((prevState) => !prevState);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (showSearchPopup) {
      const inputElement = document.querySelector(".ColumnNameInput.TextArea");
      if (inputElement) inputElement.focus();
    }
  }, [showSearchPopup]);

  return (
    <>
      {showSearchPopup && (
        <div class="searchContainer">
          <p class="search-instructions">Press Ctrl + Space to toggle search</p>
          <input
            type="text"
            placeholder="Search In All Columns..."
            class="ColumnNameInput TextArea searchInput"
            value={globalSearch}
            onChange={handleSearch}
          />
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <Container
              showSearchPopup={showSearchPopup}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columns.map((column, index) => (
                <Element key={column.id} name={column.name}>
                  <DraggableColumn
                    static_column={column}
                    index={index}
                    globalSearch={globalSearch}
                  />
                </Element>
              ))}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ColumnsContainer;
