import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ContentDisplay from "../post-components/ContentDisplay";
import { getPost, addPost, deletePost } from "../localdb/DBUtils";
import { useBookmarks } from "./BookmarkContext";
import { website } from "../../globalUtils";

const PostContainer = styled.div`
  background-color: ${({ theme }) => theme.column_background};
  border-radius: 0px;
  margin: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
  position: relative;
  &:hover > div:first-child {
    opacity: 1;
  }
`;

const Header = styled.a`
  display: flex;
  padding: 10px 5px 10px 5px;
  font-size: 16px;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.column_text || "black"};
  min-height: 40px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.1s linear;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover_background};
  }
`;

const HeaderIcon = styled.div`
  display: flex;
  position: absolute;
  padding: 5px;
  top: 24px;
  z-index: 2;
  right: 10px;
  opacity: ${({ $isbookmarked }) => ($isbookmarked === "true" ? 1 : 0)};
  justify-content: flex-end;
  cursor: pointer;
  transition: opacity 0.1s linear;
  &:hover {
    opacity: 1;
  }
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`;

const ChannelName = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.column_text || "grey"};
  font-weight: 600;
  white-space: nowrap;
`;

const Username = styled.span`
  font-size: 14px;
  margin-right: 10px;
  color: ${({ theme }) => theme.blue || "grey"};
`;

const TextBody = styled.div`
  overflow-wrap: break-word;
  position: relative;
  max-height: ${(props) => (props.$isexpanded === "true" ? "none" : "250px")};
  overflow: hidden;
  direction: rtl;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 10px; // Height of the gradient effect

    color: ${({ theme }) => theme.column_text || "grey"};

    display: ${(props) =>
      props.$isexpanded === "true"
        ? "none"
        : "block"}; // Only show when not expanded
  }
`;

const ReadMoreButton = styled.button`
  background-color: transparent;
  font-size: 14px;
  color: ${({ theme }) => theme.column_text};
  border: none;
  width: 100%;
  padding-top: 5px;
  cursor: pointer;
  display: block;
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover_background};
  }
`;

const PostTime = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 5px;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 13px;
`;

const PostBody = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.column_text || "grey"};
  padding: 5px 5px;
  padding-bottom: 0px;
  cursor: pointer;
  transition: background-color 0.1s linear;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover_background};
  }
`;

const PostUrl = styled.a`
  color: ${({ theme }) => theme.blue};
`;

const formatDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = date >= today;
  const isYesterday = date >= yesterday && date < today;

  // Use toLocaleString with UTC to keep the time the same as given
  const timeString = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  });
  const dateString = date.toLocaleDateString("en-US", {
    year: "2-digit", // Show last two digits of the year
    month: "numeric",
    day: "numeric",
    timeZone: "UTC",
  });

  if (isToday) {
    return `${timeString}`;
  } else if (isYesterday) {
    return `Yesterday at ${timeString}`;
  } else {
    return `${dateString}-${timeString}`;
  }
};

const formatDateWithAddedHours = (isoDateString) => {
  const date = new Date(isoDateString);

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = date >= today;
  const isYesterday = date >= yesterday && date < today;

  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateString = date.toLocaleDateString("en-US");

  if (isToday) {
    return `${timeString}`;
  } else if (isYesterday) {
    return `Yesterday at ${timeString}`;
  } else {
    return `${dateString}-${timeString}`;
  }
};

function Post({ post, column, onOpenPopup, is_a_bookmark = false }) {
  const [isBookmarked, setIsBookmarked] = useState(is_a_bookmark);
  const { addPostToContext, deleteContextPost } = useBookmarks();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const textRef = useRef(null); // Ref for the text container
  useEffect(() => {
    const checkContentHeight = () => {
      if (textRef.current) {
        setShowReadMore(textRef.current.scrollHeight > 250);
      }
    };
    checkContentHeight();
    window.addEventListener("resize", checkContentHeight);
    return () => {
      window.removeEventListener("resize", checkContentHeight);
    };
  }, [post.text]); // Rerun when text changes
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    const checkBookmark = async () => {
      try {
        const bookmarkedPost = await getPost(
          post.sender_id + "_" + post.timestamp
        );
        if (bookmarkedPost) {
          setIsBookmarked(true);
        }
      } catch (error) {
        console.error("Error checking bookmark status:", error);
      }
    };
    checkBookmark();
  }, [post]);
  const toggleBookmark = async () => {
    const newBookmarkState = !isBookmarked;
    setIsBookmarked(newBookmarkState);
    if (newBookmarkState) {
      try {
        addPost({
          ...post,
          post_id: post.post_id,
        });
        addPostToContext(post);
      } catch (error) {
        console.error("Error bookmarking post:", error);
        setIsBookmarked(false);
      }
    } else {
      try {
        deletePost(post.post_id);
        deleteContextPost(post.post_id);
      } catch (error) {
        console.error("Error unbookmarking post:", error);
        setIsBookmarked(true);
      }
    }
  };
  return (
    <PostContainer>
      <HeaderIcon
        onClick={toggleBookmark}
        $isbookmarked={isBookmarked.toString()}
      >
        <span className="material-icons">
          {isBookmarked ? "bookmark" : "bookmark_border"}
        </span>
      </HeaderIcon>
      <Header target="_blank" href={"https://t.me/s/" + post.sender_username}>
        <Avatar
          src={post.isApi ? post.image : website + "/" + post.image}
          alt="avatar"
        />
        <HeaderText>
          <ChannelName>{post.chat_title}</ChannelName>
          <Username>@{post.sender_username}</Username>
        </HeaderText>
      </Header>
      <PostBody
        onClick={() => {
           onOpenPopup(post.sender_username, post.post_id);
        }}
      >
        <ContentDisplay
          showPreview={column.get_preview}
          showImage={column.get_image}
          showVideo={column.get_video}
          preview_title={post.preview_title}
          preview_image={post.preview_image}
          preview_url={post.preview_url}
          preview_description={post.preview_description}
          images={post.images}
          videos={post.videos}
        />
        <TextBody
          ref={textRef}
          $isexpanded={isExpanded.toString()}
          $showgradient={(!isExpanded).toString() && showReadMore.toString()}
        >
          {post.text}
        </TextBody>
      </PostBody>
      {showReadMore && (
        <ReadMoreButton onClick={toggleReadMore}>
          {isExpanded ? (
            <span className="material-symbols-outlined">keyboard_arrow_up</span>
          ) : (
            <span className="material-symbols-outlined">
              keyboard_arrow_down
            </span>
          )}
        </ReadMoreButton>
      )}
      <PostTime>
        <PostUrl
          target="_blank"
          href={"https://t.me/s/" + post.sender_username + "/" + post.post_id}
        >
          {post.sender_username + "/" + post.post_id}
        </PostUrl>
        {post.isApi
          ? formatDateWithAddedHours(post.timestamp)
          : post.isApi
          ? formatDateWithAddedHours(post.timestamp)
          : formatDate(post.timestamp)}
      </PostTime>
    </PostContainer>
  );
}

export default Post;
