import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import "./css/DataTables.css";
import { GetAllUsers } from "./utils/UserUtil";

const GetUsersDataTables = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await GetAllUsers(setUsersData);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setUsersData({ data: [], total: 0 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>Users List</h1>
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Role</th>
              <th>Is Verified</th>
              <th>Organization Name</th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((item) => (
              <tr key={item.id}>
                <td>{`${item.first_name || "N/A"}`}</td>
                <td> {`${item.last_name || "N/A"}`}</td>
                <td>{item.email}</td>
                <td>{`${item.phone || "N/A"}`}</td>
                <td>{`${item.role || "N/A"}`}</td>
                <td>{`${item.isVerified || "N/A"}`}</td>
                <td>{`${item.organizationName || "N/A"}`}</td>
              </tr>
            ))}
            {usersData.length === 0 && (
              <tr>
                <td colSpan="4">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GetUsersDataTables;
