import { postAuth, getAuth, deleteAuth } from "../../globalUtils";
import Swal from "sweetalert2";

export const getUsers = async (organization_id) => {
  try {
    const response = await postAuth(`/organization/users`, {
      organization_id: organization_id,
    });
    const responseData = await response.json();

    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const getOrgUsers = async () => {
  try {
    const response = await getAuth(`/organization`);
    const responseData = await response.json();

    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const addUserOrg = async (
  setName,
  setEmail,
  setMaxCapacity,
  name,
  email,
  max_capacity
) => {
  try {
    const response = await postAuth("/organization/create", {
      name: name,
      email: email,
      max_capacity: max_capacity,
    });
    if (response.ok) {
      setName("");
      setEmail("");
      setMaxCapacity("");
      Swal.fire({
        icon: "success",
        title: "Organization Added",
        text: "The organization has been successfully added.",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Organization Not Added",
        text: "The organization could not be added. Please try again.",
      });
    }
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const GetAllUsers = async (setUsersData) => {
  try {
    const response = await getAuth("/admin_user/");
    const responseData = await response.json();
    setUsersData(responseData.data);
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const ViewRequests = async () => {
  try {
    const response = await getAuth("/channel_request/");
    const responseData = await response.json();
    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};
export const verifyRequestedUsers = async (id) => {
  try {
    const response = await deleteAuth(`/channel_request/${id}`);
    const responseData = await response.json();
    if (response.ok) {
      Swal.fire({
        icon: "success",
        title: "User Verified",
        text: "The user has been successfully verified.",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "User Not Verified",
        text: "The user could not be verified. Please try again.",
      });
    }
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const getAllTags = async (search = "") => {
  try {
    const response = await getAuth(`/tag?search=${search}`);
    const responseData = await response.json();
    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const addTagForChannel = async (channelId, tags) => {
  try {
    const tagArray = Array.isArray(tags) ? tags : [tags];
    const response = await postAuth("/tag/addTags", {
      channelId: channelId,
      tags: tagArray,
    });

    if (response.ok) {
      Swal.fire({
        icon: "success",
        title: "Tag Added",
        text: "The tag has been successfully added.",
      });
    } else {
      const errorData = await response.json();
      Swal.fire({
        icon: "error",
        title: "Tag Not Added",
        text: `The tag could not be added. Please try again. Error: ${errorData.message}`,
      });
    }
  } catch (error) {
    console.error("Error adding tag:", error);
    Swal.fire({
      icon: "error",
      title: "Tag Not Added",
      text: "An unexpected error occurred. Please try again.",
    });
  }
};

export const deleteTag = async (tagId) => {
  try {
    const response = await deleteAuth(`/tag/${tagId}`);
    if (response.ok) {
      Swal.fire("Deleted!", "The tag has been deleted.", "success");
    } else {
      throw new Error("Failed to delete tag");
    }
  } catch (error) {
    console.error("Error deleting tag:", error);
    Swal.fire("Error!", "Failed to delete the tag.", "error");
  }
};
export const deleteTagFromChannel = async (tagId, channelId) => {
  try {
    const response = await deleteAuth(`/tag/${tagId}/${channelId}`);
    if (response.ok) {
      Swal.fire({
        icon: "success",
        title: "Tag Updated",
        text: "The tag has been successfully updated.",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Tag Not Updated",
        text: "The tag could not be updated. Please try again.",
      });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getTagsForChannel = async (channelId) => {
  try {
    const response = await getAuth(`/tag/${channelId}`);
    const responseData = await response.json();
    return responseData.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const getAllChannels = async () => {
  try {
    const response = await getAuth("/channel/");
    const responseData = await response.json();
    return responseData.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const searchForChannel = async (search) => {
  try {
    const response = await postAuth(`/channel/searchWithTags`, {
      search: search,
    });
    const responseData = await response.json();
    return responseData; // Return the whole response object
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const addTag = async (tagName) => {
  try {
    const response = await postAuth("/tag/create", { name: tagName });
    if (response.status !== 201) {
      const errorMessage = await response.text(); // Attempt to read text if not a valid JSON
      Swal.fire(errorMessage || "Failed to add tag");
    }
    const data = await response.json();
    Swal.fire("Added!", "The tag has been added.", "success");
    return data; // Return the newly added tag
  } catch (error) {
    console.error("Error adding tag:", error);
    Swal.fire("Error!", error.message || "Failed to add the tag.", "error");
  }
};
