import React, { useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  GeoJSON,
  LayersControl,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./MapComponent.css";
import mapicon from "../RedCircleBlackOutline.png";
import L from "leaflet";
import MapComponentModal from "../map_component_modal/MapComponentModal";
import gazaGeoJson from "../gazaGeoJson"; // Import the GeoJSON data

import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const MapComponent = ({ locations }) => {
  const mapRef = useRef(null);
  const [newsVisible, setNewsVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [allGazaVisible, setAllGazaVisible] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const customIcon = L.icon({
    iconUrl: mapicon,
    iconSize: [20, 20],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    setNewsVisible(true);
  };

  const handleAllGazaClick = () => {
    setAllGazaVisible(!allGazaVisible);
  };

  const MapEffect = () => {
    const map = useMap();
    map.pm.addControls({
      position: "topleft",
      drawMarker: true,
      drawPolyline: true,
      drawRectangle: true,
      drawPolygon: true,
      drawCircle: true,
      editMode: true,
      dragMode: true,
      cutPolygon: true,
      removalMode: true,
    });

    map.on("pm:create", function (e) {
      const layer = e.layer;
      const geojson = layer.toGeoJSON();
      console.log(JSON.stringify(geojson, null, 2));
    });

    return null;
  };

  const geoJsonStyle = {
    fillColor: "red",
    weight: 2,
    opacity: 1,
    color: "red",
    fillOpacity: 0.1,
  };

  return (
    <div className="map-rotate-container">
      <MapContainer
        center={[31.5, 34.47]}
        zoom={13.4}
        style={{ height: "100vh", width: "100%" }}
        ref={mapRef}
        maxZoom={18}
        minZoom={3}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="Watercolor" checked>
            <>
              <TileLayer
                url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
                attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                minZoom={1}
                maxZoom={20}
                opacity={0.8}
              />
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://carto.com/" target="_blank">CartoDB</a>'
                opacity={1}
              />
            </>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Street Map">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              opacity={0.6}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite Imagery">
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>
        </LayersControl>

        {locations.map((location, index) => (
          <Marker
            key={index}
            position={[location.lat, location.lng]}
            icon={customIcon}
            eventHandlers={{
              click: () => toggleShow(),
              mouseover: (e) => e.target.openPopup(),
              mouseout: (e) => e.target.closePopup(),
            }}
          >
            <Popup>{location.name}</Popup>
          </Marker>
        ))}

        <MapComponentModal close={handleClose} show={show} />

        <MapEffect />
      </MapContainer>
    </div>
  );
};

export default MapComponent;
