import React, { createContext, useEffect, useRef, useState } from "react";
import { getAuth, webSse } from "../globalUtils";

export const EventSourceContext = createContext({
  subscribe: () => {},
  unsubscribe: () => {},
});

export const EventSourceProvider = ({ children }) => {
  const eventSourceRef = useRef(null);
  const subscribers = useRef(new Set());

  // Function to fetch SSE token using the access token
  const fetchSseToken = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken"); // Assuming the access token is stored in localStorage
      const response = await getAuth("/auth/sse-token", {});
      const data = await response.json();
      connectEventSource(data.sseToken); // Connect with the new token
    } catch (error) {
      console.error("Failed to fetch SSE token with access token:", error);
    }
  };

  // Function to initialize or reconnect the EventSource with the new token
  const connectEventSource = (token) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close(); // Close existing connection if open
    }

    const eventSource = new EventSource(`${webSse}/events?token=${token}`);
    eventSource.onmessage = function (event) {
      const newMessage = JSON.parse(event.data);
      subscribers.current.forEach((handler) => handler(newMessage.message));
    };

    eventSource.onerror = function (err) {
      console.error("EventSource failed:", err);
      eventSource.close();
      // Reconnect with a new token after a delay
      setTimeout(fetchSseToken, 5000);
    };

    eventSourceRef.current = eventSource;
  };

  useEffect(() => {
    fetchSseToken(); // Fetch token and connect on mount

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const subscribe = (handler) => {
    subscribers.current.add(handler);
  };

  const unsubscribe = (handler) => {
    subscribers.current.delete(handler);
  };

  return (
    <EventSourceContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </EventSourceContext.Provider>
  );
};
