import React, { createContext, useContext, useState, useEffect } from "react";
import { getAllPosts } from "../localdb/DBUtils";

const BookmarkContext = createContext();

export const BookmarkProvider = ({ children }) => {
  const [bookmarkedPosts, setBookmarkedPosts] = useState([]);

  useEffect(() => {
    getAllPosts()
      .then((posts) => {
        setBookmarkedPosts(posts);
      })
      .catch((error) => {
        console.error("Error fetching bookmarked posts:", error);
      });
  }, [bookmarkedPosts]);

  const addPostToContext = (post) => {
    setBookmarkedPosts((prevPosts) => [...prevPosts, post]);
  };

  const deleteContextPost = (postId) => {
    setBookmarkedPosts((prevPosts) =>
      prevPosts.filter((post) => post.post_id !== postId)
    );
  };

  return (
    <BookmarkContext.Provider
      value={{ bookmarkedPosts, addPostToContext, deleteContextPost }}
    >
      {children}
    </BookmarkContext.Provider>
  );
};

export const useBookmarks = () => {
  return useContext(BookmarkContext);
};
