import { postAuth, getAuth, putAuth } from "../../globalUtils";
import Swal from "sweetalert2";

export const verifyRequestedUsers = async (email) => {
  try {
    const response = await putAuth("/organization/verify", {
      email: email,
    });
    const responseData = await response.json();

    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};
export const getUsersInOrg = async () => {
  try {
    const response = await postAuth("/organization/users", {});
    const responseData = await response.json();

    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};
export const getRequestedUsers = async () => {
  try {
    const response = await getAuth("/organization/requests");
    const responseData = await response.json();

    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};

export const unverifyRequestedUsers = async (email) => {
  try {
    const response = await putAuth("/organization/unverify", {
      email: email,
    });
    const responseData = await response.json();

    return responseData.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
  }
};
