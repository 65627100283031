import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import "./css/DataTables.css";
import { getUsersInOrg, unverifyRequestedUsers } from "./utils/BusinessUtils";

const GetUsersInOrgDataTables = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getUsersInOrg();
      setUsersData(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleUnverify = async (email) => {
    try {
      await unverifyRequestedUsers(email);
      fetchData();
    } catch (error) {
      console.error("Failed to unverify user:", error);
    } finally {
      setShowPopup(false);
    }
  };
  const confirmUnverify = (user) => {
    setSelectedUser(user);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedUser(null);
  };

  const buttonStyle = {
    backgroundColor: "#ff4d4d",
    border: "none",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "15px",
    margin: "4px 1px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60%",
    height: "100%",
  };

  return (
    <div>
      <Navbar />
      <div className="container-DataTable card shadow border-0 mt-4">
        <div className="header-DataTable">
          <h1>Users List</h1>
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Is Verified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {loading ? (
  <tr>
    <td colSpan="3">Loading...</td>
  </tr>
) : (!usersData || usersData.length === 0) ? (
  <tr>
    <td colSpan="3">No users found</td>
  </tr>
) : (
              usersData.map((item) => (
                <tr key={item.id}>
                  <td>{item.first_name || "N/A"}</td>
                  <td>{item.last_name || "N/A"}</td>
                  <td>{item.email}</td>
                  <td>{item.phone || "N/A"}</td>
                  <td>{item.isVerified ? "Yes" : "No"}</td>
                  <td className="center-content">
                    <button
                      onClick={() => confirmUnverify(item)}
                      style={buttonStyle}
                      title="Unverify User"
                    >
                      Remove User
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Confirm Unverify</h2>
            <p>
              Are you sure you want to unverify {selectedUser.first_name}{" "}
              {selectedUser.last_name}?
            </p>
            <button
              onClick={() => handleUnverify(selectedUser.email)}
              style={buttonStyle}
            >
              Yes, Unverify
            </button>
            <button onClick={closePopup} style={buttonStyle}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetUsersInOrgDataTables;
