import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareButtons = ({ text, shareUrl }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          gap: "20px",
        }}
      >
        {/* <FacebookShareButton url={shareUrl} quote={text} hashtag={`#teledeck`}>
          <FacebookIcon size={40} round />
        </FacebookShareButton> */}
        <TwitterShareButton url={shareUrl} title={text} hashtag={`#teledeck`}>
          <TwitterIcon size={40} round />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl} title={text} separator=":: ">
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
      </div>
    </>
  );
};

export default ShareButtons;
