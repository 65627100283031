import { getAuth, postAuth, putAuth, deleteAuth } from "../../globalUtils";
import {
  clearColumnsAndChannels,
  addColumnWithChannels,
} from "../localdb/DBColumn";

const COLUMNS_STORAGE_KEY = "columns";
const COLUMN_API_URL = "/column";

export const getColumnsFromLocalStorage = () => {
  const columns = localStorage.getItem(COLUMNS_STORAGE_KEY);
  return columns ? JSON.parse(columns) : [];
};

export const saveColumnsToLocalStorage = (columns) => {
  localStorage.setItem(COLUMNS_STORAGE_KEY, JSON.stringify(columns.data));
};

export const addColumnToLocalStorage = (column) => {
  const columns = getColumnsFromLocalStorage();
  columns.push(column);
  saveColumnsToLocalStorage(columns);
};

export const updateColumnInLocalStorage = (id, updatedColumn) => {
  const columns = getColumnsFromLocalStorage();
  const columnIndex = columns.findIndex((column) => column.id === id);
  if (columnIndex !== -1) {
    columns[columnIndex] = updatedColumn;
    saveColumnsToLocalStorage(columns);
  }
};

export const deleteColumnFromLocalStorage = (id) => {
  const columns = getColumnsFromLocalStorage();
  const updatedColumns = columns.filter((column) => column.id !== id);
  saveColumnsToLocalStorage(updatedColumns);
};

export const getColumnsFromAPI = async () => {
  try {
    const response = await getAuth(COLUMN_API_URL);
    if (!response.ok) {
      throw new Error("Failed to fetch columns from API");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateColumnsToAPI = async (column) => {
  try {
    const response = await putAuth(`${COLUMN_API_URL}/${column.id}`, column);
    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error details:", errorData);
      throw new Error("Failed to update columns to API");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating column:", error.message);
    throw error;
  }
};

export const updateColumnsToAPIChips = async (column) => {
  try {
    // Create a deep copy of the column object
    var newColumns = JSON.parse(JSON.stringify(column));
    delete newColumns.channels;

    const response = await putAuth(
      COLUMN_API_URL + "/" + column.id,
      newColumns
    );
    if (!response.ok) {
      throw new Error("Failed to update columns to API");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteColumnFromAPI = async (id) => {
  try {
    const response = await deleteAuth(`${COLUMN_API_URL}/${id}`);
    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error details:", errorData);
      throw new Error("Failed to delete column from API");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateColumnsPositionsToAPI = async (columns) => {
  try {
    const response = await postAuth(COLUMN_API_URL + "/editPositions", {
      columns: columns,
    });
    if (!response.ok) {
      throw new Error("Failed to update columns to API");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const syncColumnsWithAPI = async () => {
  try {
    // Fetch the columns from the API
    const apiColumns = await getColumnsFromAPI();

    // Clear existing columns and channels before adding new ones
    await clearColumnsAndChannels();

    // Iterate through each column and add them along with their channels to IndexedDB
    for (let column of apiColumns.data) {
      await addColumnWithChannels({
        id: column.id,
        name: column.name,
        icon: column.icon,
        user_id: column.user_id,
        get_video: column.get_video,
        get_image: column.get_image,
        get_preview: column.get_preview,
        get_text_only: column.get_text_only,
        is_searchOnTop: column.is_searchOnTop,
        column_position: column.column_position,
        channels: column.Channels
          ? column.Channels.map((channel) => ({
              id: channel.id,
              chat_title: channel.chat_title,
              sender_username: channel.sender_username,
              image: channel.image,
            }))
          : [], // Ensure this is a flat array of channel objects
      });
    }

    // Return the API columns as they now represent the updated state of your local database
    return apiColumns;
  } catch (error) {
    console.error("Failed to sync columns with API", error);
    throw error;
  }
};
